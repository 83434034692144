import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => (
  <footer
    style={{
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '0.9rem',
      padding: '40px 20px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Vertically center
      alignItems: 'center', // Horizontally center
      height: 'auto', // Allow it to grow based on content
    }}
  >
    <div
      style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Horizontally center all content inside
        gap: '30px',
        textAlign: 'center', // Center the text
      }}
    >
      {/* Brand and Description */}
      <div>
        {/* You can add brand description here if needed */}
      </div>

      {/* Contact Us */}
      <div>
        <h3 style={{ fontSize: '1.2rem', color: '#fff', marginBottom: '20px' }}>Contact Us</h3>
        {/* New Address with Link */}
        <p style={{ marginBottom: '10px', color: '#ccc' }}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'rgb(255, 215, 0)', marginRight: '10px' }} />
          <a
            href="https://maps.app.goo.gl/SKZaBmKRbdE8LHcw8?g_st=com.google.maps.preview.copy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#ccc',  textDecoration: 'none', fontSize: '1.2rem' }}
          >
            SILK ROAD GLOBAL LIMITED, PLOT NO: C251 E, BLOCK "K" Al Hayle industrial Area Fujairah United Arab Emirates
          </a>
        </p>

        <p style={{ marginBottom: '10px', color: '#ccc', fontSize: '1.2rem' }}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'rgb(255, 215, 0)', marginRight: '10px' }} />
          UAE, Fujairah, Shk. Saif Bin Hamad Al Sharqi St.
        </p>

        {/* Phone Number as a Clickable Link */}
        <p style={{ marginBottom: '10px', color: '#ccc', fontSize: '1.2rem' }}>
          <FontAwesomeIcon icon={faPhone} style={{ color: 'rgb(255, 215, 0)', marginRight: '10px' }} />
          <a
            href="tel:+97192234333"
            style={{ color: '#ccc', textDecoration: 'none' }}
          >
            +971 92234333
          </a>
        </p>

        {/* Email as a Clickable Link */}
        <p style={{ marginBottom: '10px', color: '#ccc' }}>
          <FontAwesomeIcon icon={faEnvelope} style={{ color: 'rgb(255, 215, 0)', marginRight: '10px' }} />
          <a
            href="mailto:info@silkroadglobal.ae"
            style={{ color: '#ccc', textDecoration: 'none' , fontSize: '1.2rem'}}
          >
            info@silkroadglobal.ae
          </a>
        </p>
      </div>
    </div>

    {/* Footer Bottom */}
    <div
      style={{
        marginTop: '40px',
        borderTop: '1px dashed rgb(255, 215, 0)', // Updated to yellow dashed border
        paddingTop: '20px',
        textAlign: '-webkit-center',
        color: '#888',
        width: '-webkit-fill-available'
      }}
    >
      <p style={{ marginBottom: '5px' , fontSize: '1rem'}}>© 2024, All Rights Reserved Marwan Abbas</p>
      <p style={{ margin: '0', fontSize: '1rem' }}>
        Developed by <span style={{ color: 'rgb(255, 215, 0)' , fontSize: '1rem'}}>Xcode Team</span>
      </p>
    </div>
  </footer>
);

export default Footer;
