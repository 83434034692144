import React from 'react';

const Header = () => (
  <header
    style={{
      position: 'relative',
      height: 'auto', // Grand and impactful hero section
      backgroundImage: 'url("/pexels-tima-miroshnichenko-5640639.jpg")', // Tire-themed background image
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: '#fff',
      overflow: 'hidden',
      paddingTop: '100px', // To prevent content from being overlapped by navbar
    }}
  >
    {/* Hero Content */}
    <div
      className="hero-content"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        padding: '0 20px',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <h2
        style={{
          fontSize: '4.0rem',
          fontWeight: 'bold',
          marginBottom: '20px',
          color: '#FFD700',
          textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
        }}
      >
        Silk Road Global: Bridging Global Commerce
      </h2>
      <p
        style={{
          fontSize: '1.8rem',
          lineHeight: '2',
          marginBottom: '40px',
          color: '#fff',
          maxWidth: '900px',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
        }}
      >
        Silk Road Global Limited for Commercial Agent LLC - SPC is a progressive UAE-based company specializing in commercial agency services and international trading. With a focus on reliability and innovation, we facilitate global business transactions and foster robust partnerships.
      </p>
      <a
        href="#products"
        style={{
          display: 'inline-block',
          padding: '15px 50px',
          fontSize: '1.3rem',
          color: '#FF4500',
          backgroundColor: '#fff',
          borderRadius: '50px',
          textDecoration: 'none',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)',
          fontWeight: 'bold',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        }}
        onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
          e.target.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.8)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
          e.target.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.6)';
        }}
      >
        Explore Tires
      </a>
    </div>

    {/* Media Queries for Full Responsiveness */}
    <style>
      {`
        /* For mobile (portrait) devices */
        @media (max-width: 480px) {
          .hero-content {
            display: block;
            text-align: center;
            padding: 0 10px;
            height: auto;
          }

          h2 {
            font-size: 2rem;
          }

          p {
            font-size: 1.2rem;
            max-width: 100%;
          }

          a {
            font-size: 1.1rem;
            padding: 12px 30px;
          }
        }

        /* For tablets */
        @media (min-width: 380px) and (max-width: 844px) {
          .hero-content {
            display: block;
            text-align: center;
            padding: 0 15px;
            height: auto;
          }

          h2 {
            font-size: 2.5rem;
          }

          p {
            font-size: 1.4rem;
            max-width: 90%;
          }

          a {
            font-size: 1.2rem;
            padding: 15px 40px;
          }
        }

        /* For small laptops / desktops */
        @media (min-width: 769px) and (max-width: 1024px) {
          .hero-content {
            display: block;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          h2 {
            font-size: 3rem;
          }

          p {
            font-size: 1.5rem;
            max-width: 80%;
          }

          a {
            font-size: 1.2rem;
            padding: 15px 50px;
          }
        }

        /* For larger desktops */
        @media (min-width: 1025px) {
          .hero-content {
            display: block;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          h2 {
            font-size: 4.5rem;
          }

          p {
            font-size: 1.8rem;
            max-width: 900px;
          }

          a {
            font-size: 1.3rem;
            padding: 15px 50px;
          }
        }
      `}
    </style>
  </header>
);

export default Header;
