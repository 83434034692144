import './App.css';

import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import GlobalReach from './components/GlobalReach';
import Stakeholders from './components/Stakeholders';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => (
  <div>
  <Navbar />
    <Header />
    <About />
    <Services />
    <GlobalReach />
    <Contact />
    <Footer />
  </div>
);

export default App;
