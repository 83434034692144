import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faIdCard, faBuilding } from '@fortawesome/free-solid-svg-icons';

const Contact = () => (
  <section
    style={{
      backgroundColor: '#f9f9f9',
      padding: '60px 20px',
    }}
  >
    <div
      style={{
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <h2
        style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          marginBottom: '40px',
          color: '#333',
        }}
      >
        Contact Information
      </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Responsive grid
          gap: '30px',
        }}
      >
        {/* Phone Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Phone
          </h3>
          <a
            href="tel:+97192234333"
            style={{
              fontSize: '1.2rem',
              color: '#555',
              textDecoration: 'none',
              lineHeight: '1.6',
            }}
          >
            +971 92234333
          </a>
        </div>

        {/* Email Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              fontSize: '50px',
              color: '#10b981',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Email
          </h3>
          <a
            href="mailto:info@silkroadglobal.com"
            style={{
              fontSize: '1.2rem',
              color: '#555',
              textDecoration: 'none',
              lineHeight: '1.6',
            }}
          >
            info@silkroadglobal.ae
          </a>
        </div>

        {/* License Number Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faIdCard}
            style={{
              fontSize: '50px',
              color: '#f97316',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            License Number
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>1022822</p>
        </div>

        {/* Federal Registration Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faBuilding}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Federal Registration
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>12070823</p>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
