import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faFlag, faChartPie } from '@fortawesome/free-solid-svg-icons';

const Stakeholders = () => (
  <section
    style={{
      backgroundColor: '#ffffff',
      padding: '60px 20px', // Adjusted padding
    }}
  >
    <div
      style={{
        maxWidth: '1200px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <h2
        style={{
          fontSize: '2.5rem',
          fontWeight: 'bold',
          marginBottom: '40px',
          color: '#333',
        }}
      >
        Ownership & Key Stakeholders
      </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Responsive grid layout
          gap: '30px',
        }}
      >
        {/* Owner Card */}
        <div
          style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faUserTie}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Owner
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>Marwan Said Mohammad Abbas</p>
        </div>

        {/* Nationality Card */}
        <div
          style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faFlag}
            style={{
              fontSize: '50px',
              color: '#10b981',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Nationality
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>Jordanian</p>
        </div>

        {/* Ownership Share Card */}
        <div
          style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faChartPie}
            style={{
              fontSize: '50px',
              color: '#f97316',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Ownership Share
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>100%</p>
        </div>
      </div>
    </div>
  </section>
);

export default Stakeholders;
