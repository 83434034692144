import React from 'react';

const About = () => (
  <section
    style={{
      backgroundColor: '#f9f9f9',
      padding: '80px 20px', // Increased padding for more spacing
    }}
  >
    <div
      style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <h2
        style={{
          fontSize: '3rem', // Larger font size for the title
          fontWeight: '700',
          color: '#333',
          marginBottom: '50px', // Increased margin for better spacing
        }}
      >
        Company Overview
      </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Adjusted grid size for larger cards
          gap: '40px', // Increased gap between cards
          width: '100%',
        }}
      >
        {/* Card: Legal Structure */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '15px', // More rounded corners
            boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)', // Slightly stronger shadow for better depth
            padding: '40px', // Increased padding inside the card
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 8px 20px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div
            style={{
              width: '80px',
              height: '80px', // Larger icon container
              backgroundColor: '#eef3f8',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Replace with an icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: '40px', height: '40px', color: '#4f46e5' }} // Larger icon size
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 8.25v10.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V8.25M4.5 8.25h15M4.5 8.25L12 3m7.5 5.25L12 3"
              />
            </svg>
          </div>
          <div>
            <h3
              style={{
                fontSize: '1.8rem', // Larger card title
                fontWeight: '600',
                color: '#333',
                marginBottom: '15px',
              }}
            >
              Legal Structure
            </h3>
            <p
              style={{
                fontSize: '1.2rem', // Larger paragraph text
                color: '#555',
                lineHeight: '1.8', // Improved readability
              }}
            >
              Limited Liability Company (Single Person Company) based in the UAE.
            </p>
          </div>
        </div>

        {/* Card: Establishment */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
            padding: '40px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 8px 20px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div
            style={{
              width: '80px',
              height: '80px',
              backgroundColor: '#eef3f8',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Replace with an icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: '40px', height: '40px', color: '#4f46e5' }}
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 15.75v-3.75h3.75m-3.75 0h-3.75m0 3.75H6.75m9-9H6.75M6.75 6.75V3h9m0 0v3.75"
              />
            </svg>
          </div>
          <div>
            <h3
              style={{
                fontSize: '1.8rem',
                fontWeight: '600',
                color: '#333',
                marginBottom: '15px',
              }}
            >
              Establishment
            </h3>
            <p
              style={{
                fontSize: '1.2rem',
                color: '#555',
                lineHeight: '1.8',
              }}
            >
              Founded on May 24, 2023, with a license valid until May 24, 2026.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
