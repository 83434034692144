import React from 'react';
import { AcademicCapIcon, FireIcon, GlobeAltIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faPlane } from '@fortawesome/free-solid-svg-icons';

const Services = () => (
  <section style={{ backgroundColor: '#ffffff', padding: '60px 30px' }}> {/* Increased padding */}
    <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '50px', textAlign: 'center' }}> {/* Larger title */}
      Core Business Activities
    </h2>
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '30px' }}> {/* Increased grid size */}
      <div style={{ textAlign: 'center' }}>
        {/* First Service */}
        <FontAwesomeIcon icon={faHandshake} style={{ fontSize: '60px', color: '#4f46e5', marginBottom: '20px' }} /> {/* Larger icon */}
        <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '15px' }}> {/* Larger font */}
          Commercial Agency Services
        </h3>
        <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {/* Slightly larger text */}
          Facilitating trade agreements and representing international vendors.
        </p>
      </div>

      {/* Second Service */}
      <div style={{ textAlign: 'center' }}>
        <FireIcon style={{ width: '60px', height: '60px', color: '#f97316', marginBottom: '20px' }} /> {/* Larger icon */}
        <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '15px' }}>
          Firefighting Solutions
        </h3>
        <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
          Trading advanced firefighting equipment for high safety standards.
        </p>
      </div>

      {/* Third Service */}
      <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faPlane} style={{ fontSize: '60px', color: '#10b981', marginBottom: '20px' }} /> {/* Larger icon */}
        <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '15px' }}>
          Aircraft Tyres
        </h3>
        <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
          Specializing in multiple tire models for aircraft.
        </p>
      </div>

      {/* Fourth Service */}
      <div style={{ textAlign: 'center' }}>
        <GlobeAltIcon style={{ width: '60px', height: '60px', color: '#4f46e5', marginBottom: '20px' }} /> {/* Larger icon */}
        <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '15px' }}>
          General International Trading
        </h3>
        <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
          Offering heavy vehicle parts and specialized industrial equipment.
        </p>
      </div>
    </div>
  </section>
);

export default Services;
