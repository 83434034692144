import React, { useState } from 'react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: '20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.85)', // Dark translucent background
        boxShadow: '0 2px 15px rgba(0, 0, 0, 0.6)',
        zIndex: 10,
      }}
    >
      {/* Logo */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src="/logo_transparent.png" // Correct logo path in the public folder
          alt="Silk Road Global Logo"
          style={{
            width: '180px', // Logo size
            height: 'auto',
            objectFit: 'contain',
            cursor: 'pointer',
          }}
        />
      </div>

      {/* Hamburger Button */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        style={{
          background: 'none',
          border: 'none',
          fontSize: '2rem',
          color: '#FFD700',
          cursor: 'pointer',
          zIndex: 11,
          display: 'block',
          marginRight: '4%',
        }}
        className="hamburger-button"
      >
        ☰
      </button>

      {/* Navigation Links */}
      <ul
        style={{
          listStyle: 'none',
          display: isMenuOpen ? 'flex' : 'none', // Show menu when open
          flexDirection: 'column',
          position: 'absolute', // Ensures it's contained within the viewport
          top: '60px', // Offset the top for visibility
          right: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.95)', // Dark background for dropdown
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
          gap: '15px',
          zIndex: 100,
          width: 'auto',

          maxWidth: '250px', // Limit the width on small screens to prevent overflow
        }}
        className="nav-links"
      >
        {['Home', 'Products', 'Services', 'About Us', 'Contact'].map((item) => (
          <li key={item}>
            <a
              href={`#${item.toLowerCase().replace(' ', '')}`}
              style={{
                color: '#FFD700',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                padding: '10px 20px',
                borderRadius: '5px',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#FFD700';
                e.target.style.color = '#000';
                e.target.style.transform = 'scale(1.1)';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#FFD700';
                e.target.style.transform = 'scale(1)';
              }}
              onClick={() => setIsMenuOpen(false)} // Close menu on click
            >
              {item}
            </a>
          </li>
        ))}
      </ul>

      {/* Media Queries for responsiveness */}
      <style>
        {`
          @media (min-width: 768px) {
            .hamburger-button {
              display: none; /* Hide hamburger button for larger screens */
              marginRight: '8%',
            }
            .nav-links {
              display: flex; /* Horizontal menu on larger screens */
              position: relative;
              flex-direction: row;
              gap: 40px;
              background: none;
              box-shadow: none;
              top: initial;
              right: initial;
            }
          }
        `}
      </style>
    </nav>
  );
};

export default Navbar;
