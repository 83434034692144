import React from 'react';
import { AcademicCapIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faGlobe } from '@fortawesome/free-solid-svg-icons';

const GlobalReach = () => (
  <section
    style={{
      backgroundColor: '#f9f9f9', // Light background color
      padding: '60px 20px', // Spacious padding
    }}
  >
    <div
      style={{
        maxWidth: '1200px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <h2
        style={{
          fontSize: '2.5rem', // Larger font size for title
          fontWeight: '700',
          color: '#333',
          marginBottom: '40px',
        }}
      >
        International Business Development
      </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Responsive grid
          gap: '30px',
        }}
      >
        {/* First Point */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faHandshake}
            style={{ fontSize: '50px', color: '#4f46e5', marginBottom: '15px' }} // Icon styling
          />
          <h3
            style={{
              fontSize: '1.5rem',
              fontWeight: '600',
              marginBottom: '15px',
              color: '#333',
            }}
          >
            Building Global Partnerships
          </h3>
          <p style={{ fontSize: '1rem', lineHeight: '1.6', color: '#555' }}>
            Enabling cross-border trade by sourcing premium products and building global partnerships.
          </p>
        </div>

        {/* Second Point */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faGlobe}
            style={{ fontSize: '50px', color: '#10b981', marginBottom: '15px' }} // Icon styling
          />
          <h3
            style={{
              fontSize: '1.5rem',
              fontWeight: '600',
              marginBottom: '15px',
              color: '#333',
            }}
          >
            Streamlined Trade Processes
          </h3>
          <p style={{ fontSize: '1rem', lineHeight: '1.6', color: '#555' }}>
            Streamlining international trade processes to support businesses in reaching new markets.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default GlobalReach;
